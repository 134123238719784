#btnBack{
    position: relative;
    margin-bottom: .6rem !important;
    text-align: center;
    margin-top: -13px;
    box-shadow: none;
    background-color: transparent !important;
    color: #ffffff !important;
    padding: 4px;    
}
.btn.btn-outline-danger.icon.fa-arrow-left{
    background-color: rgb(237, 28, 36);
    color: white !important;
    margin-bottom: 0.5rem !important;
    border-color: transparent !important;
    border-radius: 2px; 
    transition: all .2s ease-in-out; 
    font-style: normal;
    font-weight: bold; 
    width: 105px !important;
        -webkit-box-shadow: 0px 1px 2px 2px gray !important; 
    -moz-box-shadow: 0px 1px 2px 2px gray !important; 
    box-shadow: 0px 1px 2px 2px gray !important; 
    border-radius: 5px;
}
.btn.btn-outline-danger.icon.fa-arrow-left:hover{
    opacity: 0.6 !important;
}
 .unstyled-button {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    position: relative !important;
    box-shadow: none !important;;
    background-color: transparent !important;
    color: #ffffff !important;
  }

  #btnHistory :focus{
      outline: none !important;
      box-shadow: none  !important;
  }