.white {
  color: white !important;
}
.swal2-popup .swal2-styled.swal2-cancel {
  color: white !important;
  background-color: #dc3545 !important;
  font-size: 14px !important;
  height: 100% !important;
  transition: all 0.2s ease-in-out !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  color: white !important;
  background-color: #28a745 !important;
  font-size: 14px !important;
  height: 100% !important;
  transition: all 0.2s ease-in-out !important;
}
.swal2-confirm:hover {
  transform: scale(1.05) !important;
}
.swal2-cancel:hover {
  transform: scale(1.05) !important;
}
.swal2-popup .swal2-close {
  border: 2px solid white !important;
  color: white !important;
  background-color: #f27474 !important;
  border-radius: 5px !important;
  font-size: 2.2em !important;
  transition: all 0.2s ease-in-out !important;
}
.swal2-close:hover {
  transform: scale(1.05) !important;
  background-color: rgb(237, 28, 36) !important;
}
.swal2-popup .swal2-title {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.475em !important;
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.swal2-container.swal2-center {
  margin: 0px;
  padding: 58px;
}

.MuiTableCell-stickyHeader {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #dee2e64d !important;
  font-weight: bold !important;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.75);
}

.capitalized {
  text-transform: capitalize !important;
}
.MuiTablePagination-actions {
  margin-left: 10px !important;
}
.MuiIconButton-root.Mui-disabled,
.MuiButtonBase-root.Mui-disabled {
  width: 35px;
  height: 35px;
}
.status-requested {
  background-color: rgb(237, 28, 36);
  border-radius: 2px;
  color: white;
  margin: 0px;
  padding: 2px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.status-pending {
  background-color: goldenrod;
  border-radius: 2px;
  color: white;
  margin: 0px;
  padding: 2px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.status-approved {
  background-color: green;
  border-radius: 2px;
  color: white;
  margin: 0px;
  padding: 2px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.status-declined {
  background-color: #343a4069;
  border-radius: 2px;
  color: white;
  margin: 0px;
  padding: 2px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.main-logo {
  max-width: 40px;
  max-height: 40px;
}
.main-logo_1 {
  max-width: 190px;
  max-height: 190px;
}
.important-jumbotron {
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
.page-patch {
  color: #808080b3;
  font-size: 10px;
  margin: 0px 20px;
  text-transform: uppercase;
}
.page-patch:hover {
  color: #808080;
}
.title-upper {
  text-transform: uppercase;
  font-size: 12px;
}
.policy-cordion {
  color: gray;
  text-align: right !important;
  display: block;
  margin-top: -22px;
}
.custom-color {
  color: #555555;
}
.menu-name {
  color: white;
  font-size: 12px;
}
.menu-code {
  font-size: 10px;
}
.image-menu {
  border-radius: 5%;
  width: 90px;
  height: 95px;
}
.green {
  color: green;
}
.custom-arrow-right {
  font-size: 12px !important;
  color: black !important;
  font-weight: lighter !important;
}
.custom-card-header {
  background-color: white !important;
}
.title-company {
  font-size: 12px !important;
}
.red {
  color: red;
}
.yellow {
  color: yellow;
}
a#TblContactDirectory_previous {
  padding: 5px;
  color: #343a40;
  font-size: 14px;
}
a#TblContactDirectory_next {
  padding: 5px;
  color: #343a40;
  font-size: 14px;
}

table {
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  border: 1px solid #dee2e6;
}

a.paginate_button.current {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(100%, #dcdcdc)
  );
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
  padding: 8px;
  border-radius: 2px;
}
a.paginate_button {
  padding: 5px;
  color: #343a40;
  font-size: 14px;
}
#TblContactDirectory.table.table-bordered.dataTable {
  font-size: 14px;
}
.divLeft {
  text-align: left;
}
.divSecond {
  padding: 5px 10px !important;
}
.defaultText {
  font-size: 12px !important;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)
  > .swal2-modal {
  min-width: 300px;
}
@media screen and (max-width: 750px) {
  .card-benefit {
    width: 26rem !important;
  }
}
@media screen and (max-width: 290px) {
  .swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)
    > .swal2-modal {
    min-width: 275px;
  }
  .card-benefit {
    width: 18rem !important;
  }
}
@media screen and (max-width: 400px) {
  #TblContactDirectory.table.table-bordered.dataTable,
  a#TblContactDirectory_previous,
  a#TblContactDirectory_next,
  a.paginate_button.current,
  a.paginate_button {
    font-size: 11px;
  }
  .card-benefit {
    width: 19rem !important;
  }
}
@media screen and (max-width: 280px) {
  .card-benefit {
    width: 16rem !important;
  }
}
.card-benefit {
  width: 30rem;
}
.card-benefit-default {
  min-width: 350px !important;
}
.fa-4 {
  font-size: 22px !important;
}
.size-effect {
  transition: all 0.2s ease-in-out;
}
.size-effect:hover {
  transform: scale(1.1) !important;
}
.size-effect-x2 {
  transition: all 0.2s ease-in-out;
}
.size-effect-x2:hover {
  transform: scale(1.5) !important;
}
.not-allowed {
  transition: all 0.1s ease-in-out;
}
.not-allowed:hover {
  cursor: not-allowed !important;
}
.vacation-calendar > .react-datepicker-wrapper {
  width: 100%;
}
.vacation-add {
  height: 620px;
}
.title-required {
  font-size: 12px;
  border-radius: 2px;
  color: rgb(237, 28, 36);
  margin: 0px;
  padding: 2px;
}
.title-optional {
  font-size: 11px;
  color: #adb5bd;
  font-style: italic;
  font-weight: bold;
}
ul.no-bullets {
  list-style-type: none !important;
}
.bold-font {
  font-weight: bold !important;
}
.title-lowercase {
  text-transform: lowercase;
  font-size: 12px;
}
.vacation-square-radio > input[type="radio"] + label:before {
  border-radius: 10% !important;
}
.fc-scroller {
  overflow-y: hidden !important;
}
.fc-daygrid-day-events {
  transition: all 0.2s ease-in-out !important;
}
.fc-daygrid-day-events:hove {
  transform: scale(2.05) !important;
  cursor: pointer;
}
.table-group-compensation {
  background-color: #e9ecef;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.table-group-compensation-total {
  font-size: 14px;
  font-weight: bold;
}

.my-carnet-size {
  text-align: center;
  width: 100%;
  border: 2px solid black;
  border-radius: 3px !important;
  margin-top: -2px;
}
.title-benefit-upper {
  text-transform: uppercase;
  font-size: 18px !important;
  color: white !important;
  font-weight: bold !important;
}
.body-details-search {
  background-color: white;
  margin: 0px;
  padding: 20px;
  border-radius: 4px;
}
#btnHistory {
  transition: all 0.2s ease-in-out;
  margin: 0px 2px;
}
#btnHistory:hover {
  transform: scale(1.3) !important;
  cursor: pointer;
}
.react-datepicker-popper {
  left: 42px !important;
}
.img-carousel {
  min-height: 120px !important;
}
.login-service-desk-adm {
  background-color: black !important;
  color: white !important;
}
.img-user-grid {
  width: 30px !important;
  height: 30px !important;
}
div#TblUserAdm_filter {
  text-align: left !important;
}

.textblack {
  color: black !important;
}
.mail-size {
  font-size: 1.35em;
}
.mail-size:hover {
  cursor: pointer !important;
}
span#spBoxCounter {
  display: inline-table;
  position: fixed;
  border-radius: 10px;
  font-size: 0.45em;
  margin-top: -5px;
  margin-left: -12px;
  font-family: system-ui;
  color: white;
}
.envelopeDefault {
  opacity: 0.4;
}
.counterDefault {
  visibility: hidden;
}
td.details-control {
  content: "\f055"no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  content: "\f055"no-repeat center center;
}

#TblMessage_wrapper {
  font-size: 96% !important;
  font: normal !important;
}

#TblMessage_wrapper table.dataTable {
  font-size: 13px;
}

#TblMessage_wrapper table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}
#TblMessage_wrapper div.dt-buttons {
  text-align: left !important;
}
#TblMessage_filter.dataTables_filter {
  float: left !important;
  margin: 0px;
  padding: 5px;
}

#TblMessage_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: 78%;
  height: 40px;
}

#TblMessage_wrapper.dataTables_wrapper div.dataTables_filter label {
  font-weight: bold;
}

#TblMessage_wrapper.dataTables_wrapper.no-footer > .dt-buttons > .dt-button {
  border: none !important;
  font-size: 12px;
}

#TblMessage_wrapper.dataTables_wrapper.no-footer
  > .dt-buttons
  > .dt-button
  input[type="button"],
button {
  border: none !important;
  box-shadow: none !important;
}
#TblMessage_info {
  font-size: 13px;
}
.divChildRow {
  border: 1px solid #ad172b;
  border-radius: 2px;
  margin-top: 5px;
}
.btn-icon {
  width: 4px !important;
}
.msgText {
  margin: 0px;
  padding: 0px 6px;
  position: relative;
}
.headMessage {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}
.mainTextMessage {
  background-color: #ce1b28ba;
  color: white !important;
  margin: 0.8px;
}
.TextMessage {
  background-color: white;
}

#btnBoxMessage {
  color: white;
}
#btnBoxMessage:hover {
  cursor: pointer;
}
.image-birthday {
  transition: all 0.2s ease-in-out;
}
.image-birthday:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
}
.cssCongrats {
  color: red;
  text-shadow: 2px 2px 5px rgb(255, 51, 0);
}
.CongratsH1 {
  letter-spacing: 10px;
}
.congratsImg {
  width: 110px;
  height: 110px;
}
@media screen and (max-width: 500px) {
  .congratsImg {
    width: 40px;
    height: 40px;
  }
  .CongratsH1 {
    letter-spacing: 5px;
  }
}
@media screen and (max-width: 360px) {
  .congratsImg {
    width: 30px !important;
    height: 30px !important;
  }
  .CongratsH1 {
    letter-spacing: 3px !important;
  }
}
.evaluation-square-radio > input[type="radio"] + label:before {
  border-radius: 100% !important;
  width: 33px;
  height: 33px;
}
.evaluation-body {
  margin: 0px;
  padding: 20px;
  background-color: white;
  border-radius: 4px !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.span-header {
  margin: 0px 5px !important;
  display: grid !important;
}

.maindiv {
  border-radius: 10px;
  border: 2px solid #ed1c24;
  padding: 20px;
  /*width: 1400px;*/
  width: 95%;
  height: 90%;
  background-image: url("http://soycorripio.com.do/Intranet/themes/codebase/img/autoevaluacion/bg_la.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 102% 102%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.msgdiv {
  border-radius: 10px;
  border: 2px solid #ed1c24;
  padding: 20px;
  width: 1100px;
  height: 500px;
  background-image: url("http://soycorripio.com.do/Intranet/themes/codebase/img/autoevaluacion/msg_la.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 102% 102%;
  margin: auto;
}

#logo {
  width: 120px !important;
  height: 120px !important;
}
.txt-v {
  width: 10px;
  word-wrap: break-word;
  font-size: 25px !important;
  margin: 0 !important;
}
a {
  color: #ed1c24;
}
td {
  vertical-align: top;
  font-family: "Arial";
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 75px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  font-family: "Arial";
  font-size: 14px;
}
.modal-content {
  position: relative;
  background-color: #dfdfdf;
  margin: auto;
  padding: 0;
  width: 70%;
  height: 65%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 30px;
  border: 2px solid #ed1c24;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-header {
  padding: 2px 16px;
  background-color: #ff2000;
  color: white;
}
.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #ff2000;
  color: white;
}

.texto {
  position: absolute;
}

.texto-1 {
  position: absolute;
  margin-left: 100px;
}
