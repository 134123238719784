@media screen and (max-width: 736px) {
    
    .banner{
        /*height: 10rem !important;*/
        height: auto !important;
        width: 100% !important;
        margin: 0px !important; 
    }

}
.banner{
top: -20px;
width: 99.5%;
height: auto !important;
margin: 4px;   
-webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.75);
box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.75);
}
.defaultLogo {
    background-size: cover;
    background-color: white !important;
    position: relative;
    height: 444px;
    width: 100% !important;
    margin: 0px;
    padding: 100px;
}
.CarouselEffet {
    transform: translate3d(-200%, 0px, 0px);
    height: 787px;
    transition-duration: 150ms;
}

    