.css-g1d714-ValueContainer,.css-b8ldur-Input,.css-b8ldur-Input,.css-yk16xz-control{
    height:24px;
    position: static !important;
    

}
.css-1pahdxg-control{
    border: 1px solid #ce1b28 !important;
    box-shadow: 0 0 0 0.5px #ce1b28 !important;
}

 
