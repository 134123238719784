.textInfo{
    display: grid;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 1.5;
    color: #91741c;
    text-align: left;
    white-space: nowrap;
    background-color: #ffc10717;
    border: 1px solid #ced4da;
    border-radius: .25rem;
 

}
.textInfoBlue{
     display: grid;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 1.5;
    color: #07606d;
    text-align: left;
    white-space: nowrap;
    background-color: #007bff33;
    border: 1px solid #ced4da;
    border-radius: .25rem;
   
}
.btnRequest {
    color: white !important;
    -webkit-box-shadow: 0px 1px 2px 2px gray !important; 
    -moz-box-shadow: 0px 1px 2px 2px gray !important; 
    box-shadow: 0px 1px 2px 2px gray !important;   
    position: relative;
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 1rem!important;
    font-size: 70%;
  
    text-transform: uppercase;
    
}
.form-request{
    border: 1px solid #dc3545 !important;
    border-radius: 5px;
}


.htmlMyrequests{min-height: 800px;font-family:sans-serif;line-height:1.15;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:transparent}.bodyMyrequests{margin:0;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;font-size:1rem;font-weight:400;line-height:1.5;color:#212529;text-align:left;background-color:#fff}