.modal-content{
   top: 45px !important; 
}
 
.ismodal-enable{
    position:absolute !important;
}
.modal-header-color{
  background-color:  #b70007 !important;
}
.button-DC{
  background-color:  #b70007 !important;
  color: white !important;
} 
.button-DC:hover{
  
  color: white !important;
 
 
}  

.bg-Green {
    background-color: #28a7451c !important;
    font-weight:bold;
}
.color-Icon{
  color:#eb0028;
}
